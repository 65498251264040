<cc-table
  [ccEmptyRowTemplate]="emptyRowTemplate"
  [ccSearchPlaceholder]="'Search for an invoice...'"
  ccPadding="medium"
>
  <ng-container slot="cc-table-title"> Invoices</ng-container>

  <ng-container slot="cc-table">
    <thead>
      <tr>
        <th ccTh>Invoice #</th>
        <th ccTh>Sender's Company</th>
      </tr>
    </thead>

    <tbody>
      <ng-container *ngFor="let invoice of invoices">
        <tr
          (click)="invoiceClick.emit(invoice)"
          [ccClickable]="clickable"
          ccTr
        >
          <td>
            <div class="invoice-column">
              <div class="invoice-column__details">
                <div class="invoice-column__details-title">{{ invoice.invoiceNumber }}</div>

                <div
                  [style.background-color]="getStatusBgColor(invoice)"
                  [style.color]="getStatusFgColor(invoice)"
                  class="invoice-column__details-status"
                >
                  {{ getStatusText(invoice) }}
                </div>
              </div>

              <div class="invoice-column__description">
                Total invoice amount: {{ invoice.totalAmountDue | currency : 'USD' }}
              </div>
            </div>
          </td>

          <td ccTd>
            <div class="performer-column">
              <img
                [alt]="invoice.retailer"
                [ccTooltip]="invoice.retailer"
                src="https://picsum.photos/seed/{{ invoice.invoiceNumber }}/200"
              />

              <div class="performer-column__name">{{ invoice.retailer }}</div>
            </div>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </ng-container>
</cc-table>

<ng-template #emptyRowTemplate> There are no invoices to display.</ng-template>
