import { CurrencyPipe, NgForOf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import type { BPIInvoice } from '@design/overlays/business-object-modal/business-object';
import { TooltipDirective } from '@design/overlays/tooltip/tooltip.directive';
import { TableComponent } from '@design/table/table.component';
import { TdComponent } from '@design/table/td/td.component';
import { ThComponent } from '@design/table/th/th.component';
import { TrComponent } from '@design/table/tr/tr.component';

@Component({
  selector: 'cc-company-invoices',
  standalone: true,
  imports: [TableComponent, TdComponent, ThComponent, TrComponent, NgForOf, CurrencyPipe, TooltipDirective],
  templateUrl: './company-invoices.component.html',
  styleUrls: ['./company-invoices.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyInvoicesComponent {
  @Input()
  invoices: BPIInvoice[] = [];

  @Input()
  clickable = false;

  @Output()
  invoiceClick = new EventEmitter<BPIInvoice>();

  getStatusText(invoice: BPIInvoice): string {
    switch (invoice.status) {
      case 'paid':
        return 'Paid';
      case 'disputed':
        return 'Disputed';
      default:
        return 'Unknown';
    }
  }

  getStatusFgColor(invoice: BPIInvoice): string {
    switch (invoice.status) {
      case 'paid':
        return '#08A262';
      case 'disputed':
        return '#AB2345';
      default:
        return '#000000';
    }
  }

  getStatusBgColor(invoice: BPIInvoice): string {
    switch (invoice.status) {
      case 'paid':
        return '#D6F9EA';
      case 'disputed':
        return '#FBE4E9';
      default:
        return '#FFFFFF';
    }
  }
}
